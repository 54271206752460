@import '../bootstrap/variables/index';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.react-select {
  .rs__control {
    border-radius: $input-border-radius;
    background-color: $input-bg;
    min-height: 42px;
    padding-top: 1px;
    padding-bottom: 0;
    padding-right: 55px;

    &--is-focused {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color !important;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $input-focus-box-shadow;
      }
    }

    &--is-disabled {
      background-color: $input-disabled-bg;
      border-color: $input-border-color;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }
  }

  &.is-invalid .rs__control {
    border-color: $danger;
  }

  .rs__single-value--is-disabled {
    color: $input-color;
  }

  .rs__indicators {
    position: absolute;
    right: 0;
  }
}
