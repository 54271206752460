.box-ratio-16-9 {
  height: 100%;
  overflow: hidden;
  padding-bottom: 56.7%;
  background: white;
  position: relative;

  .box-ratio-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
