.invalid-feedback {
  position: absolute;
  margin-top: 0.05rem;
  width: auto;
  right: 0;
}

.form-check .invalid-feedback,
.form-check ~ .invalid-feedback {
  right: auto;
  left: 0;
}
