:root {
  scroll-behavior: auto !important;
}
.form-group {
  margin-bottom: 1.1rem;
}
.form-check-label {
  line-height: 1;
}
.small {
  font-size: $small-font-size;
}
.form-check {
  margin-bottom: 0;
}
.form-check-input {
  margin-top: 0.26em;
}
.form-check-label {
  padding-top: 3px;
  user-select: none;
}
.dropdown-toggle::after {
  border: none;
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236c757d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem 0.8rem;
  width: 1rem;
  height: 0.8rem;
  transition-duration: 0.2s;
  transition-property: transform;
}
.dropdown-toggle[aria-expanded='true']::after {
  transform: rotate(-180deg);
}
.form-switch .form-check-input:checked {
  background-color: $tertiary;
  border-color: $tertiary;
}
.form-switch.label-left {
  padding-left: 0;
  padding-right: 2.5em;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: -2.5em;
    //margin-top: 0.25em;
  }
}

.form-floating > label {
  position: absolute;
  top: 11px;
  left: 8px;
  height: auto;
  padding: 0 0.2rem;
  pointer-events: none;
  border: none;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.form-floating > .form-control {
  padding: 0.625rem 0.8rem;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: inherit;
  line-height: 1.3;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.9;
  background: #fbfbfb;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  font-size: 0.9rem;
  top: -1px;
  width: auto;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.input-group-text {
  padding: 0 0.4rem;
}

.btn-transparent {
  @include button-variant(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1));

  text-transform: uppercase;
  letter-spacing: 0.04rem;
}

.btn-outline-transparent {
  @include button-outline-variant($primary);
}

// move the following to themes?
.btn-signal,
.btn-signal:focus,
.btn-signal:hover,
.btn-signal.active:focus,
.btn-signal:active:focus {
  color: $white !important;
  border-color: $white;
}

.fslightbox-fade-out {
  animation: fslightbox-fade-out 5s ease !important;
}
.fslightbox-fade-out-strong {
  animation: fslightbox-fade-out-strong 5s ease !important;
}
.fslightbox-toolbar-button:first-child {
  display: none;
}
