.navbar-brand {
  padding: 0;
}

.card-title {
  line-height: 1;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: $theme-font-bold;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

.btn-signal,
.btn-signal:focus,
.btn-signal:hover,
.btn-signal.active:focus,
.btn-signal:active:focus {
  color: $black !important;
  border-color: $white;
}
