aside * {
  line-height: 1;
}
section#content * {
  //line-height: 1.35;
}

html,
body {
  //overflow-x: hidden; --> disables "position: sticky"
}

main {
  margin-top: $headerHeight;
  min-height: 55vh;
}

.btn-unstyled {
  border: none;
  background: none;
  padding: 0;
}

.anchor {
  display: block;
  position: relative;
  top: -$navHeight;
  visibility: hidden;
}

.content-subtitle {
  @include font-content-subtitle;
}

table th {
  padding-right: 0.5rem;
}

table th,
table td {
  text-align: left;
  vertical-align: top;
}

.bg-theme {
  @include theme-background;
}