img {
  //image-rendering: -moz-crisp-edges; // mozilla is worse than before
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

.img-ratio-16-9 {
  overflow: hidden;
  padding-bottom: 56.7%;
  position: relative;
  width: 100%;

  img {
    width: 100.5%;
    position: absolute;
    object-fit: cover;
  }
}

.img-ratio-1-1 {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  img {
    width: 100.5%;
    position: absolute;
    object-fit: cover;
  }
}
