// grow
.an-grow {
  transition: all 0.3s ease-in-out;

  //-webkit-transform: translateZ(0);
  //transform: scale(.99) translateZ(0);
  ////transform: scale(.99);
  //-webkit-transition: transform 400ms;
  //transition: transform 400ms;

  &:hover {
    transform: scale(1.009);
    //transform: perspective(1px) scale(1);
  }
}

/* Float */
.an-up {
  position: relative;
  top: 0;
  transition: top ease 0.2s;
}
.an-up:hover,
.an-up:focus,
.an-up:active {
  top: -3px;
}

/* Forward */
.an-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.an-forward:hover,
.an-forward:focus,
.an-forward:active {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}

// wobble horizontal
@-webkit-keyframes an-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes an-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.an-wobble-horizontal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);

  &:hover,
  &:focus,
  &:active {
    -webkit-animation-name: an-wobble-horizontal;
    animation-name: an-wobble-horizontal;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}

.an-wobble-horizontal-trigger {
  &:hover .an-wobble-horizontal {
    -webkit-animation-name: an-wobble-horizontal;
    animation-name: an-wobble-horizontal;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}
