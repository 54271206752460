@font-face {
  font-family: 'Expletus';
  src: url('https://res.cloudinary.com/difp9jq5j/raw/upload/themes/carreisen-ostschweiz/fonts/expletus-sans/expletus-sans.woff2')
  format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'ExpletusBoldCustom';
  src: url('https://res.cloudinary.com/difp9jq5j/raw/upload/themes/carreisen-ostschweiz/fonts/expletus-sans/expletus-sans.woff2')
  format('woff2');
  font-display: swap;
  font-weight: 700;
}
